import { createHttpEndpoint } from '../../utils'

/**
 * Log the current user outs from the Identity stack.
 */
export const getLogout = createHttpEndpoint({
  method: 'GET',
  path: '/auth/logout',
  operationId: 'getOauthLogout',
})
