import { CACHE_KEY as STATE_CACHE_KEY } from '../composables/useAuthState'
import { CACHE_KEY as ENDPOINTS_CACHE_KEY } from '../composables/useEndpoints'
import { CACHE_KEY as PKCE_CACHE_KEY } from '../composables/usePkce'
import { scopedStorage, sharedStorage } from '../storage'

/**
 * Clear the different internal storages from all auth related items.
 *
 * @example
 * const { clearAuthStorage } = useClearAuthStorate()
 *
 * clearAuthStorage() // Call this whenever you want.
 */
export async function clearAuthStorages() {
  sharedStorage.removeItem(STATE_CACHE_KEY)
  sharedStorage.removeItem(PKCE_CACHE_KEY)
  scopedStorage.removeItem(ENDPOINTS_CACHE_KEY)

  // TODO: Compatibility layer with the implementation that lives on
  // TODO: Pastrami. This won't be required anymore after the whole
  // TODO: authentication flow takes place on Front Office.
  localStorage.removeItem('oauth2.oauthState')
  localStorage.removeItem('oauth2.pkceVerifier')
  sessionStorage.removeItem('oauth2.next_page')
  sessionStorage.removeItem('oauth2.endpoints')
}
