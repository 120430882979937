import { useRouter, useRuntimeConfig } from '#imports'

import { authLegacyAPI, oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import { clearAuthStorages } from '../utils/clearAuthStorage'

import { useAuthOrigin } from './useAuthOrigin'

/*
 * Log the current user out.
 *
 * This functions takes care of logging people out from everywhere (Badoom,
 * the Identity stack, etc), and clears the different internal storages. In
 * the end, people get redirect to the default location given as part of
 * the module configuration.
 *
 * @example
 * const { logout } = useLogout()
 *
 * logout() // Call this whenever you want.
 */
export function useLogout() {
  const router = useRouter()
  const config = useRuntimeConfig().public.oauth

  async function logout() {
    await $httpFetch(authLegacyAPI.postClientLogout)
    await $httpFetch(oauthAPI.getLogout, { baseURL: useAuthOrigin() })

    await clearAuthStorages()

    // Force a reload to clear the in-memory data (stores, components, etc).
    const redirect = router.resolve(config.defaultRedirectLocationWhenGuest)
    window.location.assign(redirect.href)
  }

  return { logout }
}
